<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 70vw;" class="q-pa-none">
      <q-card-section class="row">

        <div class="text-h6 text-center">
          {{ $t('Mark not shipped') }}
        </div>

        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>

      <q-card-section>
        <q-table
            class="my-q-table"
            row-key="id"
            :rows="items"
            :columns="cols"
            :pagination="pagination"
            hide-bottom
            hide-header
            binary-state-sort
        >
          <template v-slot:body="props">
            <labelling-mark-not-shipped-table
                :data="props"
                @removeItem="handleRemoveItem"
                @change="handleChange"
            />
          </template>
        </q-table>
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
            color="light-blue-9"
            text-color="white"
            :label="$t('Save')"
            class="q-mr-md"
            size="md"
            no-caps
            unelevated
            @click="save"
        />

        <q-btn
            color="grey-10"
            text-color="grey-10"
            class="q-mr-md"
            :label="$t('Discard')"
            outline
            no-caps
            @click="close"
        />

        <q-btn
            color="light-blue-9"
            text-color="white"
            :label="$t('Replace all barcodes and save')"
            class="q-mr-md"
            size="md"
            no-caps
            unelevated
            @click="saveBarcodes"
        />


      </q-card-section>

    </q-card>
  </q-dialog>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
import LabellingMarkNotShippedTable from '@/apps/app/components/modals/LabellingMarkNotShippedTable.vue'

export default {
  name: 'LabellingMarkNotShippedModal',
  emits: ['submit'],
  components: {
    LabellingMarkNotShippedTable
  },
  data () {
    return {
      isOpen: false,
      items: [],
      pagination: {
        page: 1,
        rowsPerPage: 250,
      },
      cols: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          name: 'pool',
          align: 'center',
          field: 'pool'
        },
        {
          name: 'total',
          align: 'center',
          field: 'total'
        },
        {
          name: 'actions',
          align: 'left',
          field: 'actions'
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
  },
  methods: {
    ...mapMutations([
      'addWarningNotification'
    ]),
    save () {

      this.$emit('submit', this.items)

      this.isOpen = false
    },
    close () {
      this.items = []
      this.isOpen = false
    },
    saveBarcodes () {
      this.items = this.items.map(e => {
        if (e.barcodes && e.barcodes.length > 0) {
          if (!e.eav) {
            e.eav = {
              'delivery-services-request-place-item-replace-barcode': true
            }
          } else {
            e.eav = {
              ...e.eav,
              'delivery-services-request-place-item-replace-barcode': true
            }
          }
        }

        return e
      })
      this.save()
    },
    handleChange (data, item) {
      this.items = this.items.map(e => {
        if (e.id === item.id) {
          if (!e.eav) {
            e.eav = {
              'delivery-services-request-place-item-replace-barcode': data
            }
          } else {
            e.eav = {
              ...e.eav,
              'delivery-services-request-place-item-replace-barcode': data
            }
          }
        }
        return e
      })
    },
    open (items) {
      this.items = items

      this.isOpen = true
    },
    handleRemoveItem (item) {
      this.items = this.items.filter(e => e.id !== item.id)
    }
  }
}

</script>